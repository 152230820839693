import React, { useState, useEffect, useRef, useCallback } from 'react';
import './ScreenMarket.css';
import { useSocket } from './SocketContext';
import StockChartContainer from './ExchangeApp/StockChartContainer';
import StockListAuto from "./ExchangeApp/StockListAuto";

function ScreenMarket() {
  const [selectedStock, setSelectedStock] = useState(null);
  const chartContainerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const socket = useSocket();
  const [sampleRate, setSampleRate] = useState(1);

  useEffect(() => {
    const updateChartWidth = () => {
      if (chartContainerRef.current) {
        setChartWidth(chartContainerRef.current.offsetWidth);
      }
    };

    updateChartWidth();
    window.addEventListener('resize', updateChartWidth);

    return () => window.removeEventListener('resize', updateChartWidth);
  }, []);
  
  const handleStockSelect = useCallback((stock) => {
    setSelectedStock(stock);
  }, []);

  const handleTrade = useCallback((action, quantity) => {
    if (socket && selectedStock) {
      socket.emit('trade', {
        action,
        stockName: selectedStock.name,
        quantity: parseInt(quantity, 10)
      });
    }
  }, [socket, selectedStock]);

  const handleSampleRateChange = (rate) => {
    setSampleRate(rate);
  };

  return (
      <div className="screen-market">
        <div id="iframe-effects">
          <div id="scan-effect"></div>
          <div id="scanlines"></div>
        </div>
        <div className="stock-container">
          <StockListAuto
              onSelectStock={handleStockSelect}
          />
          <div className="stock-chart-and-trade">
            <div ref={chartContainerRef} className="stock-chart-container">
              {selectedStock && (
                  <StockChartContainer
                      stock={selectedStock}
                      width={chartWidth}
                      padding={10}
                      sampleRate={sampleRate}
                      onSampleRateChange={handleSampleRateChange}
                  />
              )}
            </div>
          </div>
        </div>
      </div>
  );
}

export default ScreenMarket;
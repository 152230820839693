import React, { useState, useEffect, useCallback } from 'react';
import { useSocket } from '../SocketContext';
import useStockData from './useStockData';

function StockListAuto({ onSelectStock }) {
  const [stocks, setStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const socket = useSocket();

  useEffect(() => {
    if (!socket) return;

    socket.on('stockPrices', (updatedStocks) => {
      setStocks(prevStocks => {
        if (JSON.stringify(prevStocks) !== JSON.stringify(updatedStocks)) {
          return updatedStocks;
        }
        return prevStocks;
      });
    });

    return () => {
      socket.off('stockPrices');
    };
  }, [socket]);


  const handleStockSelect = useCallback((stock) => {
    if (selectedStock && JSON.stringify(selectedStock) === JSON.stringify(stock)) {
      return; // Évite de sélectionner le même stock
    }
    if (selectedStock) {
      socket.emit('leaveStockRoom', selectedStock.name);
    }
    setSelectedStock(stock);
    onSelectStock(stock);
    socket.emit('joinStockRoom', stock.name);

    setTimeout(() => {
      const nextIndex = stocks.findIndex(s => s.name === stock.name) + 1;
      if (nextIndex < stocks.length) {
        handleStockSelect(stocks[nextIndex]);
      } else {
        handleStockSelect(stocks[0]);
      }
    }, 10000);
  }, [selectedStock, socket, onSelectStock, stocks]);

  useEffect(() => {
    if (!selectedStock && stocks.length > 0) {
      handleStockSelect(stocks[0]);
    }
  }, [stocks, handleStockSelect, selectedStock, socket]);

  return null;
}

export default StockListAuto;